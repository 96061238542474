import { useStaticQuery, graphql } from 'gatsby';

const AllPostsQuery = () => {
  return useStaticQuery(graphql`
    query allPosts {
      Posts: allWpPost {
        edges {
          node {
            id
            title
            content
            author {
              node {
                name
              }
            }
            categories {
              nodes {
                name
              }
            }
            tags {
              nodes {
                name
              }
            }
            date
            commentCount
            uri
            featuredImage {
              node {
                localFile {
                  childrenImageSharp {
                    fluid(maxWidth: 340, maxHeight: 180) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);
};

export { AllPostsQuery };
