import { Link } from 'gatsby';
import React, { FC } from 'react';

export interface FilterProps {
  Filters: [{}];
  titleref: any;
}

const TagsFilter: FC<FilterProps> = ({ Filters, titleref }) => {
  return (
    <div className="common-filter" ref={titleref}>
      <h3 className="section-title">Tags</h3>
      <ul className="common-filter-list">
        {Filters.map((filter: any) => {
          if (filter.node.count > 0) {
            return (
              <li className="common-filter-list-item" key={filter.node.name}>
                <Link to={`tag/${filter.node.slug}`}>{filter.node.name}</Link>
              </li>
            );
          }
          return null;
        })}
      </ul>
    </div>
  );
};

export { TagsFilter };
