/* eslint-disable react/no-danger */
import React, { FC } from 'react';

const ArticleContent: FC = ({ ArticleSummary }: any) => {
  return (
    <div
      className="articles-summary"
      dangerouslySetInnerHTML={{
        __html: ArticleSummary,
      }}
    />
  );
};
export { ArticleContent };
