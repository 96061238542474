import React from 'react';
import { Seo } from 'components';

const Layout = ({ children, pageTitle, description, image, type }: any) => {
  return (
    <div>
      <Seo
        pageTitle={pageTitle}
        description={description}
        image={image}
        type={type}
      />
      <main className="main-content">
        <div className="container">{children}</div>
      </main>
    </div>
  );
};

export { Layout };
