/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-danger */
import React from 'react';
import moment from 'moment';
import Img from 'gatsby-image';
import { normalizePath } from 'utils/get-url-path';
import { useStaticQuery, graphql, Link } from 'gatsby';
import Slider from 'react-slick';

const RelatedArticlesSlider = () => {
  const data = useStaticQuery(graphql`
    query RelatedArticlesQuery {
      RelatedPosts: allWpPost {
        edges {
          node {
            id
            title
            uri
            date
            categories {
              nodes {
                name
                uri
              }
            }
            featuredImage {
              node {
                localFile {
                  childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);
  const articles = data?.RelatedPosts?.edges;

  const settings = {
    autoplay: true,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    swipeToSlide: true,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 500,
        settings: {
          centerMode: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };
  return (
    <div className="article-related">
      <h3 className="article-related-title">Related Articles</h3>

      <Slider {...settings}>
        {articles.map((article: any) => {
          return (
            <div key={article.node.id} className="article-related-card">
              <Img
                className="article-related-card-image"
                fluid={
                  article.node.featuredImage?.node.localFile.childImageSharp
                    .fluid
                }
              />
              <div className="article-related-card-content">
                <div className="article-related-card-content-item">
                  <Link
                    to={normalizePath(article.node.categories.nodes[0].uri)}
                  >
                    <div className="article-related-card-content-item-category">
                      {article.node.categories.nodes[0].name}
                    </div>
                  </Link>
                  <div className="article-related-card-content-item-date">
                    {moment(article.node.date).format('MMM D, YYYY')}
                  </div>
                </div>
                <Link to={normalizePath(article.node.uri)}>
                  <h3 className="article-related-card-content-title">
                    {article.node.title}
                  </h3>
                </Link>
              </div>
            </div>
          );
        })}
      </Slider>
    </div>
  );
};

export { RelatedArticlesSlider };
