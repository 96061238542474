import React, { useEffect, useState } from 'react';
import { Link } from 'gatsby';
import JsQuirksLogo from 'assets/svg/logo.inline.svg';
import { Menu } from 'components';
import { Script } from 'gatsby';
import { useLocation } from '@reach/router';

export const Header = ({ setFoundArticles, ArticlesListData }: any) => {
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [closeNavBarOnClick, setCloseNavBarOnClick] = useState(false);
  const toggleNavBar = () => {
    setIsNavOpen(current => !current);
  };
  const headerNavClass = isNavOpen ? 'active' : '';
  useEffect(() => {
    document.body.classList.toggle('nav-open', isNavOpen);
  }, [isNavOpen]);
  const { pathname } = useLocation();

  useEffect(() => {
    setCloseNavBarOnClick(window.innerWidth <= 991);
  }, [pathname]);
  return (
    <header className={`header ${headerNavClass}`}>
      <Script id="commento" src="https://commento.ozzi.tech/js/count.js" />
      <div className="container">
        <div className="header_container">
          <Link to="/">
            <JsQuirksLogo className="logo" />
          </Link>
          <div className="header-wrapper">
            <Menu
              toggleNav={toggleNavBar}
              setFoundArticles={setFoundArticles}
              ArticlesListData={ArticlesListData}
              closeNavBarOnClick={closeNavBarOnClick}
            />
            <button
              type="button"
              className="hamburger-menu"
              id="hamburger-menu"
              onClick={toggleNavBar}
            >
              <span />
              <span />
              <span />
            </button>
          </div>
        </div>
      </div>
    </header>
  );
};
