import React, { useState, useEffect } from 'react';
import ScrollIcon from 'assets/svg/scrollicon.inline.svg';

export const ScrollArrow = () => {
  const [showScroll, setShowScroll] = useState(false);

  const checkScrollTop = () => {
    if (!showScroll && window.pageYOffset > 400) {
      setShowScroll(true);
    } else {
      setShowScroll(false);
    }
  };

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  useEffect(() => {
    window.addEventListener('scroll', checkScrollTop);
    return () => {
      window.removeEventListener('scroll', checkScrollTop);
    };
  }, []);
  return (
    <button
      type="button"
      className={`page-scroll ${showScroll ? 'show-top' : ''}`}
      onClick={scrollTop}
    >
      <ScrollIcon className="scrollTop" />
    </button>
  );
};
