/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { FC } from 'react';
import { Helmet } from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';
import { useLocation } from '@reach/router';

interface SeoProps {
  description?: string;
  pageTitle: string;
  type?: string;
  image?: any;
}

const Seo: FC<SeoProps> = ({ pageTitle, description, image }) => {
  const { pathname } = useLocation();
  return (
    <StaticQuery
      query={seoDetailsQuery}
      render={data => {
        const {
          site: {
            siteMetadata: {
              defaultTitle,
              defaultDescription,
              siteUrl,
              defaultImage,
              googleSiteVerification,
              faceBookAppId,
              twitterusername,
              isDevEnvironment,
            },
          },
        } = data;

        const seoTitle = pageTitle || defaultTitle;
        const seoDescription = description || defaultDescription;
        const metaUrl = `${siteUrl}${pathname}`;
        const metaImage = `${siteUrl}${image}` || `${siteUrl}${defaultImage}`;
        const canonical = pathname ? `${siteUrl}${pathname}` : null;
        const googleVerificationId = googleSiteVerification;
        const twitterUsername = twitterusername || '';
        const fbAppId = faceBookAppId || '';
        const isDev = isDevEnvironment;
        return (
          <Helmet
            htmlAttributes={{}}
            title={seoTitle}
            titleTemplate={`%s | ${defaultTitle}`}
            link={
              canonical
                ? [
                    {
                      rel: 'canonical',
                      href: canonical,
                    },
                  ]
                : []
            }
            meta={[
              {
                name: 'description',
                content: seoDescription,
              },
              {
                name: 'image',
                content: metaImage,
              },
              {
                name: 'og:image:width',
                content: 200,
              },
              {
                name: 'og:image:height',
                content: 150,
              },
              {
                property: 'og:title',
                content: seoTitle,
              },
              {
                property: `og:url`,
                content: metaUrl,
              },
              {
                property: 'og:description',
                content: seoDescription,
              },
              {
                property: 'og:type',
                content: 'website',
              },
              {
                name: 'twitter:card',
                content: 'summary',
              },
              {
                name: 'twitter:creator',
                content: twitterUsername,
              },
              {
                name: 'twitter:title',
                content: seoTitle,
              },
              {
                name: 'twitter:description',
                content: seoDescription,
              },
              {
                name: 'twitter:image',
                content: metaImage,
              },
              {
                name: 'google-site-verification',
                content: googleVerificationId,
              },
              {
                name: 'fb:app_id',
                content: fbAppId,
              },
            ]
              .concat(
                metaImage
                  ? [
                      {
                        property: `og:image`,
                        content: metaImage,
                      },
                      {
                        property: `og:image:alt`,
                        content: seoTitle,
                      },
                    ]
                  : []
              )
              .concat(
                isDev
                  ? [
                      {
                        property: `robots`,
                        content: `noindex`,
                      },
                    ]
                  : []
              )}
          />
        );
      }}
    />
  );
};

export { Seo };

const seoDetailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        defaultTitle: title
        defaultDescription: description
        faceBookAppId
        googleSiteVerification
        defaultImage: image
        isDevEnvironment
        siteUrl
        type
      }
    }
  }
`;
