/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Link } from 'gatsby';
import { CategoryQuery } from 'queries/categories';

export const Categories = () => {
  const data = CategoryQuery();
  const posts = data?.allWpCategory?.edges;

  return (
    <div className="categories">
      <h3 className="section-title">Categories</h3>
      <ul className="categories_list">
        {posts.map((post: any) => {
          if (post.node.count > 0) {
            return (
              <li key={post.node.name}>
                <Link to={post.node.uri}>
                  {post.node.name}
                  <span> {post.node.count}</span>
                </Link>
              </li>
            );
          }
          return null;
        })}
      </ul>
    </div>
  );
};
