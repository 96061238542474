import React, { useEffect, useState } from 'react';
import { Header, Footer } from 'components';
import { ScrollArrow } from 'components/ScrollArrow';
import 'styles/style.scss';

const PageLayout = ({ children, ArticlesListData }: any) => {
  const [foundArticles, setFoundArticles] = useState([]);
  useEffect(() => {
    setFoundArticles(ArticlesListData);
  }, []);
  return (
    <div>
      <Header
        setFoundArticles={setFoundArticles}
        ArticlesListData={ArticlesListData}
      />
      <div>{children}</div>
      <ScrollArrow />
      <Footer />
    </div>
  );
};

export default PageLayout;
