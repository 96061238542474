import { useStaticQuery, graphql } from 'gatsby';

const MenuQuery = () => {
  return useStaticQuery(graphql`
    query MenuQuery {
      menu: allWpMenu {
        edges {
          node {
            name
            menuItems {
              nodes {
                id
                path
                label
                url
                menu {
                  node {
                    name
                  }
                }
              }
            }
          }
        }
      }
    }
  `);
};

export { MenuQuery };
