import React, { FC } from 'react';
import LeftArrowIcon from 'assets/svg/arrow-prev.inline.svg';
import RightArrowIcon from 'assets/svg/arrow-next.inline.svg';
import classnames from 'classnames';
import { usePagination, DOTS } from './usePagination';

export interface PaginationPropsType {
  currentPage: any;
  totalCount: any;
  pageSize: any;
  onPageChange: any;
}

export const Pagination: FC<PaginationPropsType> = ({
  currentPage,
  totalCount,
  pageSize,
  onPageChange,
}) => {
  const siblingCount = 1;

  const paginationRange: any = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });
  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }
  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };
  const lastPage = paginationRange[paginationRange.length - 1];
  return (
    <div className="pagination-section">
      <button
        type="button"
        className="pagination-left-arrow"
        onClick={onPrevious}
        disabled={currentPage === 1}
      >
        <LeftArrowIcon />
      </button>
      <ul className="pagination-list">
        {paginationRange.map((pageNumber: any) => {
          if (pageNumber === DOTS) {
            return (
              <li key={pageNumber} className="pagination-item dots">
                &#8230;
              </li>
            );
          }
          return (
            <li
              className={classnames('pagination-item', {
                active: pageNumber === currentPage,
              })}
              key={pageNumber}
            >
              <button type="button" onClick={() => onPageChange(pageNumber)}>
                {pageNumber}
              </button>
            </li>
          );
        })}
      </ul>

      <button
        type="button"
        className="pagination-right-arrow"
        onClick={onNext}
        disabled={currentPage === lastPage}
      >
        <RightArrowIcon />
      </button>
    </div>
  );
};
