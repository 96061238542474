import React from 'react';
import moment from 'moment';
import { useStaticQuery, graphql, Link } from 'gatsby';
import Img from 'gatsby-image';

export const HomeBanner = () => {
  const data = useStaticQuery(graphql`
    query HomeBannerQuery {
      HomeBannerPost: allWpPost(limit: 3) {
        edges {
          node {
            id
            date
            title
            slug
            uri
            author {
              node {
                name
              }
            }
            featuredImage {
              node {
                localFile {
                  childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
            categories {
              nodes {
                name
                uri
              }
            }
          }
        }
      }
    }
  `);
  const posts = data.HomeBannerPost.edges;
  return (
    <div className="home-banner">
      {posts.map((post: any) => (
        <div className="home-banner-column" key={post.node.id}>
          <div className="home-banner-column-image">
            {post?.node?.featuredImage?.node?.localFile?.childImageSharp
              ?.fluid && (
              <Img
                fluid={
                  post.node.featuredImage.node.localFile.childImageSharp.fluid
                }
              />
            )}
          </div>
          <div className="home-banner-column-overlay" />
          <div className="home-banner-column-content">
            <Link
              to={post.node.categories.nodes[0].uri}
              className="home-banner-column-content-link-category"
            >
              <div className="home-banner-column-content-category">
                {post.node.categories.nodes[0].name}
              </div>
            </Link>
            <Link
              to={post.node.uri}
              className="home-banner-column-content-link-article"
            />
            <Link to={post.node.uri}>
              <h5 className="home-banner-column-content-title">
                {post.node.title}
              </h5>
            </Link>
            <div className="home-banner-column-content-details">
              <div className="home-banner-column-content-detail-item">
                <span>{post.node.author.node.name}</span>
              </div>
              <div className="home-banner-column-content-detail-item">
                <span>{moment(post.node.date).format('MMM D, YYYY')}</span>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
