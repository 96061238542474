/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC } from 'react';
import ReactLogo from 'assets/svg/react-logo.inline.svg';
import HTMLLogo from 'assets/svg/html-logo.inline.svg';
import JSLogo from 'assets/svg/js-logo.inline.svg';
import CSSLogo from 'assets/svg/css-logo.inline.svg';
import GitLogo from 'assets/svg/git-logo.inline.svg';

const CategoryIcons: FC = ({ IconsData }: any) => {
  return (
    <>
      {IconsData.posts.nodes.map((post: any) => {
        let Icon: any;
        switch (post.categories.nodes[0].name) {
          case 'HTML':
            Icon = <HTMLLogo />;
            break;
          case 'React':
            Icon = <ReactLogo />;
            break;
          case 'Javascript':
            Icon = <JSLogo />;
            break;
          case 'CSS':
            Icon = <CSSLogo />;
            break;
          case 'Git':
            Icon = <GitLogo />;
            break;
          default:
            Icon = <HTMLLogo />;
            break;
        }
        return { Icon };
      })}
    </>
  );
};
export { CategoryIcons };
