import React from 'react';
import { Link } from 'gatsby';

const ArticleTag = ({ ArticleTagsdata }: any) => {
  const data = ArticleTagsdata;
  const taglist = data?.tags?.nodes;
  const authordetails = data.author;
  const limit = 3;
  return (
    <div className="articletags">
      <div className="articletags-heading">
        <div className="articletags-title">Article Tags</div>
        <ul className="articletags-links">
          {taglist?.map((tags: any, index: number) => {
            return index < limit ? (
              <li key={tags.name}>
                <Link to={tags.uri}>{tags.name} </Link>
              </li>
            ) : null;
          })}
        </ul>
      </div>
    </div>
  );
};

export { ArticleTag };
