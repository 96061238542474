import React from 'react';
import ReactLogo from 'assets/svg/react-logo.inline.svg';
import HTMLLogo from 'assets/svg/html-logo.inline.svg';
import JSLogo from 'assets/svg/js-logo.inline.svg';
import CSSLogo from 'assets/svg/css-logo.inline.svg';
import GitLogo from 'assets/svg/git-logo.inline.svg';
import YoutubeLogo from 'assets/svg/youtube.inline.svg';
import LinkedinLogo from 'assets/svg/linkedin.inline.svg';
import FacebookLogo from 'assets/svg/facebook.inline.svg';
import TwitterLogo from 'assets/svg/twitter.inline.svg';

export const Icons = ({ name }: any) => {
  switch (name) {
    case 'HTML':
      return <HTMLLogo />;
    case 'React':
      return <ReactLogo />;
    case 'JavaScript':
      return <JSLogo />;
    case 'CSS':
      return <CSSLogo />;
    case 'Git':
      return <GitLogo />;
    case 'Facebook':
      return <FacebookLogo />;
    case 'Twitter':
      return <TwitterLogo />;
    case 'Linkedin':
      return <LinkedinLogo />;
    case 'Youtube':
      return <YoutubeLogo />;
    default:
      return <></>;
  }
};
