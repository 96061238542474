import { useStaticQuery, graphql } from 'gatsby';

const CategoryQuery = () => {
  return useStaticQuery(graphql`
    query MyQuery {
      allWpCategory {
        edges {
          node {
            link
            name
            count
            uri
          }
        }
      }
    }
  `);
};

export { CategoryQuery };
