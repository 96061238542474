/* eslint-disable no-unused-expressions */
/* eslint-disable react/no-this-in-sfc */
import React, { FC, useState, useRef } from 'react';
import { Link, navigate } from 'gatsby';
import Search from 'assets/svg/search.inline.svg';
import Close from 'assets/svg/close.inline.svg';
import { AllPostsQuery } from 'queries/allPosts';
import lunr from 'lunr';
import { normalizePath } from 'utils/get-url-path';
import RightIcon from 'assets/svg/rightarrow.inline.svg';

export const SearchBar: FC = ({ setIsSearchOpen, isSearchOpen }: any) => {
  const allPosts = AllPostsQuery();
  const [searchValue, setSearchValue] = useState('');
  const [isSearchTextOpen, setSearchTextOpen] = useState(true);
  const [searchResults, setSearchResults] = useState<any[]>([]);
  const searchClass = isSearchOpen ? 'search-active' : '';
  const searchInputRef = useRef(null);
  const [showBlock, setShowBlock] = useState(false);
  const handleClick = () => {
    setSearchValue('');
    setIsSearchOpen((current: boolean) => !current);
    setTimeout(() => searchInputRef.current.focus(), 1000);
    setShowBlock(false);
  };
  const searchPosts = allPosts.Posts.edges.map((item: any) => {
    const tags = item.node.tags.nodes.map((tag: any) => tag.name);
    const categories = item.node.categories.nodes.map((tag: any) => tag.name);
    return {
      id: item.node.id,
      title: item.node.title,
      uri: item.node.uri,
      content: item.node.content,
      tags,
      categories,
    };
  });
  const index = lunr(function () {
    this.ref('id');
    this.field('title');
    this.field('categories');
    this.field('tags');
    this.field('content');
    searchPosts.forEach(function (doc: any) {
      this.add(doc);
    }, this);
  });

  const onEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      setSearchTextOpen(false);
      if (searchValue) {
        navigate(`/search/q=?${searchValue}`, { replace: true });
      } else {
        setShowBlock(true);
      }
    }
  };
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
    const res = index.search(`${e.target.value}*`);
    const searchIds = new Set(res.map(({ ref }) => ref));
    setSearchResults(
      allPosts.Posts.edges.filter((item: any) => searchIds.has(item.node.id))
    );
    setSearchTextOpen(true);
  };

  return (
    <div className={`search ${searchClass}`}>
      <div className="search-items">
        <input
          type="text"
          value={searchValue}
          placeholder="Enter text"
          className="search-input"
          onChange={onChange}
          onKeyDown={onEnter}
          ref={searchInputRef}
        />
        {isSearchOpen ? (
          <Close onClick={handleClick} className="search-icon" />
        ) : (
          <Search onClick={handleClick} className="search-icon" />
        )}
      </div>
      {searchValue.length === 0 
        ? showBlock && (
            <div className="search-dataResult-novalue">
              <p> Please enter the text</p>
            </div>
          )
        : null}
      {searchValue.length > 2 && isSearchTextOpen && (
        <div className="search-dataResult">
          {searchResults.slice(0, 3).map((result: any) => {
            return (
              <Link
                className="search-item-link"
                to={normalizePath(result.node.uri)}
              >
                <div className="search-dataItem">
                  <p className="search-dataItem-value">{result.node.title}</p>
                </div>
              </Link>
            );
          })}
          <div className="show-all-block">
            {(searchResults.length > 3 && (
              <Link
                to={`/search/q=?${searchValue}`}
                className="show-all-block-button"
              >
                <span className="show-all-block-text">
                  Show all search results
                </span>
                <RightIcon />
              </Link>
            )) ||
              (searchResults.length === 0 && <span>No Matches Found</span>)}
          </div>
        </div>
      )}
    </div>
  );
};
