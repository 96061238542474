/* eslint-disable no-unused-expressions */
import React, { FC, useState, useEffect } from 'react';
import { Link } from 'gatsby';
import { MenuQuery } from 'queries/menu';
import { HeaderNavigation, SearchBar } from 'components';

const Menu: FC = ({ toggleNav, closeNavBarOnClick }: any) => {
  const data = MenuQuery();
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const navLinks = data?.menu?.edges.filter((menuLink: any) => {
    return menuLink.node.name === 'mainmenu';
  });
  const navDisplay = isSearchOpen ? 'hide-menu' : '';
  useEffect(() => {
    const hamburgerMenu = document.getElementById('hamburger-menu');
    hamburgerMenu!.classList.toggle('hide-menu', isSearchOpen);
  }, [isSearchOpen]);

  return (
    <>
      <div className="nav-menu">
        <nav className={`nav-container ${navDisplay}`}>
          <ul className="nav-list">
            {navLinks[0].node.menuItems.nodes.map((item: any) => (
              <li className="nav-item" key={item.label}>
                <Link
                  to={item.path}
                  onClick={() => {
                    setTimeout(() => {
                      closeNavBarOnClick ? toggleNav() : null;
                    }, 50);
                  }}
                >
                  {item.label}
                </Link>
              </li>
            ))}
          </ul>
          <HeaderNavigation
            toggleNav={toggleNav}
            closeNavBarOnClick={closeNavBarOnClick}
          />
        </nav>
        <SearchBar
          setIsSearchOpen={setIsSearchOpen}
          isSearchOpen={isSearchOpen}
        />
      </div>
    </>
  );
};
export { Menu };
