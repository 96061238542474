import React from 'react';
import JsQuirksLogo from 'assets/svg/logo.inline.svg';
import { Link } from 'gatsby';
import { normalizePath } from 'utils/get-url-path';
import { MenuQuery } from 'queries/menu';
import { SocialMedia, SubscribeForm } from 'components';
import { CategoryQuery } from 'queries/categories';

const Footer = () => {
  const data = MenuQuery();
  const navLinks = data?.menu?.edges.filter((menuLink: any) => {
    return menuLink.node.name === 'footermenu';
  });
  const categoryData = CategoryQuery();
  const posts = categoryData?.allWpCategory?.edges;
  const footersummary = `We are crew of web development professionals who like to share the knowledge about different aspect of web development and its inner architects. JSQuicks is the roadmap for beginners who are interested in learning and developing interactive website. `;
  const copyrights = ` © Copyright ©2022 All rights reserved`;
  return (
    <footer>
      <div className="container">
        <div className="footer">
          <div className="footer-logo">
            <Link to="/">
              <JsQuirksLogo className="logo" />
            </Link>
            <div className="footer-copyrights">{copyrights}</div>
            <p className="footer-summary">{footersummary}</p>
          </div>
          <div className="footer-content">
            <div className="footer-quicklinks">
              <h2 className="footer-header">Quick Links</h2>
              <ul className="footer-links">
                {navLinks[0].node.menuItems.nodes.map((menuItem: any) => {
                  const path = menuItem.url;
                  return (
                    <li key={menuItem.id}>
                      <Link key={menuItem.url} to={normalizePath(path)}>
                        {menuItem.label}
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className="footer-categories">
              <h2 className="footer-header">Categories</h2>
              <ul className="footer-links">
                {posts.map((post: any) => {
                  if (post.node.count > 0) {
                    const path = post.node.uri;
                    return (
                      <li key={post.node.name}>
                        <Link key={post.node.uri} to={normalizePath(path)}>
                          {post.node.name}
                        </Link>
                      </li>
                    );
                  }
                  return null;
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
export { Footer };
