/* eslint-disable react/no-danger */
import React from 'react';
import moment from 'moment';
import Img from 'gatsby-image';
import { Link } from 'gatsby';
import UserIcon from 'assets/svg/usericon.inline.svg';
import RightIcon from 'assets/svg/rightarrow.inline.svg';
import { normalizePath } from 'utils/get-url-path';
import { truncate } from 'utils';
import {Count, Icons} from 'components';

export function LatestArticles({ Allarticles }: any) {
  return (
    <div className="latest-articles-wrapper">
      {Allarticles.length === 0 && <p>Sorry there are no articles</p>}
      {Allarticles.map((post: any) => {
        const { content } = post.node;
        const trimcontent = truncate(content, 250);
        const articletitle = post.node.title;
        const trimtitle = truncate(articletitle, 45);
        return (
          <div className="latest-articles-item" key={post.node.title}>
            <div className="latest-articles-image">
              {post?.node?.featuredImage && (
                <Img
                  fluid={
                    post.node.featuredImage.node.localFile
                      ?.childrenImageSharp[0].fluid
                  }
                />
              )}
            </div>
            <div className="latest-articles-content">
              <div className="latest-articles-content-item-description">
                <div className="latest-articles-content-button">
                  <Link to={normalizePath(post.node.uri)}>
                    <h5 className="latest-articles-title">{trimtitle}</h5>
                  </Link>
                  <div className="latest-articles-details">
                    <span>
                      <UserIcon /> {post.node.author.node.name}
                    </span>
                    <span className="latest-articles-list-items">
                      {moment(post.node.date).format('MMMM D, YYYY')}
                    </span>
                    <span className="latest-articles-list-items">
                       <Count articleUrl={post.node.uri}/>
                    </span>
                  </div>
                  {!!post.node.content && (
                    <div
                      className="latest-articles-summary"
                      dangerouslySetInnerHTML={{
                        __html: trimcontent,
                      }}
                    />
                  )}
                </div>
                <div className="latest-articles-category">
                  <Link to={normalizePath(post.node.uri)}>
                    <span className="latest-articles-link">Read More</span>{' '}
                    <RightIcon />
                  </Link>
                  <div className="latest-articles-category-value">
                    <Icons name={post.node.categories.nodes[0].name} />
                    <Link to={normalizePath(post.node.uri)}>
                      <span>{post.node.categories.nodes[0].name}</span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}
