/* eslint-disable no-unused-expressions */
import React, { FC } from 'react';
import { Link } from 'gatsby';
import { MenuQuery } from 'queries/menu';

const HeaderNavigation: FC = ({ toggleNav, closeNavBarOnClick }: any) => {
  const data = MenuQuery();

  const headerLink = data?.menu?.edges.filter((menuLink: any) => {
    return menuLink.node.name === 'navigation';
  });
  return (
    <ul className="header_menu">
      {headerLink[0]?.node.menuItems.nodes.map((item: any) => (
        <li key={item.label}>
          <Link
            to={item.path}
            onClick={() => {
              setTimeout(() => {
                closeNavBarOnClick ? toggleNav() : null;
              }, 50);
            }}
          >
            {item.label}
          </Link>
        </li>
      ))}
    </ul>
  );
};

export { HeaderNavigation };
