exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-templates-category-page-tsx": () => import("./../../../src/templates/category-page.tsx" /* webpackChunkName: "component---src-templates-category-page-tsx" */),
  "component---src-templates-index-tsx": () => import("./../../../src/templates/index.tsx" /* webpackChunkName: "component---src-templates-index-tsx" */),
  "component---src-templates-search-tsx": () => import("./../../../src/templates/search.tsx" /* webpackChunkName: "component---src-templates-search-tsx" */),
  "component---src-templates-single-page-tsx": () => import("./../../../src/templates/single/Page.tsx" /* webpackChunkName: "component---src-templates-single-page-tsx" */),
  "component---src-templates-single-post-tsx": () => import("./../../../src/templates/single/Post.tsx" /* webpackChunkName: "component---src-templates-single-post-tsx" */),
  "component---src-templates-tag-page-tsx": () => import("./../../../src/templates/tag-page.tsx" /* webpackChunkName: "component---src-templates-tag-page-tsx" */)
}

