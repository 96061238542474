/* eslint-disable react/no-danger */
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import {
  Layout,
  ArticleTag,
  RelatedArticlesSlider,
  RelatedArticle,
} from 'components';
import { normalizePath } from 'utils/get-url-path';
import RightIcon from 'assets/svg/rightarrow.inline.svg';
import LeftIcon from 'assets/svg/arrow-prev.inline.svg';
import UnderLineIcon from 'assets/svg/underline.inline.svg';
import { Divider } from '@chakra-ui/core';
import classNames from 'classnames';

const BlogPost = ({ data }: any) => {
  const { nextPage, previousPage, page } = data;
  const { title, content, featuredImage, author, date } = page;
  const category = page?.categories?.nodes[0].name;
  useEffect(() => {
    function progressBarScroll() {
      const progressBar = document.getElementById('progressBar');
      if (progressBar) {
        const winScroll =
          document.body.scrollTop || document.documentElement.scrollTop;
        const height =
          document.documentElement.scrollHeight -
          document.documentElement.clientHeight;
        const scrolled = (winScroll / height) * 100;
        document.getElementById('progressBar').style.width = `${scrolled}%`;
      }
    }

    window.onscroll = function () {
      progressBarScroll();
    };
  }, []);
  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.innerWidth < 990) {
        setScroll(window.scrollY > 500);
      } else {
        setScroll(window.scrollY > 900);
      }
    });
  }, []);
  return (
    <>
      <div
        className={classNames('progress-bar-content', {
          scrolled: scroll,
        })}
      >
        <h1 className="progress-bar-content-title container">{title}</h1>
        <div className="progress-bar" id="progressBar" />
      </div>
      <Layout pageTitle={title} description="Learn" image="Logo.png">
        <div className="article-container">
          {!!featuredImage?.node?.localFile?.childImageSharp && (
            <div className="article-banner">
              <Img fluid={featuredImage.node.localFile.childImageSharp.fluid} />
            </div>
          )}

          <div className="article-body">
            <div className="article-body-wrapper">
              <h1 className="article-banner-content-title">{title}</h1>
              <div className="article-banner-content-details">
                {!!author?.node?.name && (
                  <div className="article-banner-content-details-item">
                    <img
                      src={author?.node?.avatar.url}
                      alt={author?.node?.name}
                    />
                    <div>
                      {author.node.name}
                      <span className="article-banner-content-details-item article-banner-content-details-date">
                        {moment(date).format('MMM D, YYYY')}
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="article-body-layout">
              <div
                className="article-content"
                dangerouslySetInnerHTML={{ __html: content }}
              />
              <RelatedArticle />
            </div>
            <ArticleTag ArticleTagsdata={page} />
            <div className="article-page-pagination">
              <div className="article-page-pagination-wrapper">
                {!!previousPage && (
                  <>
                    <div className="article-page-image">
                      {!!previousPage?.featuredImage?.node?.localFile
                        ?.childImageSharp && (
                        <div className="article-image-section">
                          <Img
                            fluid={
                              previousPage.featuredImage.node.localFile
                                ?.childImageSharp.fluid
                            }
                          />
                        </div>
                      )}
                    </div>
                    <Link to={normalizePath(previousPage.uri)}>
                      <div className="article-page-pagination-prev-list">
                        <p className="article-page-pagination-prev-list-items">
                          <LeftIcon /> Prev Post
                        </p>
                      </div>
                      <div className="article-page-pagination-details">
                        <p>{previousPage.title}</p>
                      </div>
                    </Link>
                  </>
                )}
              </div>

              <div className="article-page-pagination-wrapper">
                {!!nextPage && (
                  <>
                    <Link to={normalizePath(nextPage.uri)}>
                      <div className="article-page-pagination-next-list">
                        <p className="article-page-pagination-next-list-items">
                          {' '}
                          Next Post <RightIcon />
                        </p>
                      </div>
                      <div className="article-page-pagination-details">
                        <p className="article-page-pagination-details-title">
                          {nextPage.title}
                        </p>
                      </div>
                    </Link>
                    <div className="article-page-image">
                      {!!nextPage?.featuredImage?.node?.localFile
                        ?.childImageSharp && (
                        <div className="article-image-section">
                          <Img
                            fluid={
                              nextPage.featuredImage.node.localFile
                                ?.childImageSharp.fluid
                            }
                          />
                        </div>
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>

            <div className="article-page-underline">
              <UnderLineIcon />
            </div>
            {category ? <RelatedArticlesSlider /> : ''}
          </div>
        </div>
      </Layout>
    </>
  );
};

export { BlogPost };
