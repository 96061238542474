/* eslint-disable react/no-danger */
import React, { useEffect } from 'react';
import ChatIcon from 'assets/svg/chat.inline.svg';

const Count = ({ articleUrl }: any) => {
    const url =articleUrl+'#commento';
  
    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://commento.ozzi.tech/js/count.js';
        script.id = "commento";
        document.getElementById('commento')?.remove();
        document.head.appendChild(script);
     }, []);

    return (
        <>
         <ChatIcon /><a href={url}>0 comments</a>
        </>
    );
};


export { Count };
