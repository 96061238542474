import React from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import moment from 'moment';
import Img from 'gatsby-image';

export const RelatedArticle = () => {
  const data = useStaticQuery(graphql`
    query RelatedArticleQuery {
      allWpCategory(limit: 3) {
        edges {
          node {
            link
            name
            posts {
              nodes {
                title
                featuredImage {
                  node {
                    localFile {
                      childrenImageSharp {
                        fluid {
                          ...GatsbyImageSharpFluid
                        }
                      }
                    }
                  }
                }
                author {
                  node {
                    name
                  }
                }
                date
                uri
              }
            }
          }
        }
      }
    }
  `);
  const posts = data?.allWpCategory?.edges;

  return (
    <div className="related-articles">
      <div className="related-articles-main">
        <h3 className="section-title">Related Articles</h3>
        {posts.map((post: any) => {
          return (
            <>
              {post?.node?.posts?.nodes.length !== 0 && (
                <div className="related-articles-item" key={post.node.title}>
                  <div className="related-articles-image">
                    {post?.node?.posts?.nodes[0]?.featuredImage && (
                      <Img
                        fluid={
                          post.node.posts?.nodes[0]?.featuredImage.node
                            .localFile?.childrenImageSharp[0].fluid
                        }
                      />
                    )}
                  </div>
                  <div className="related-articles-details">
                    <Link to={post.node.posts?.nodes[0]?.uri}>
                      <h5 className="related-articles-content-title">
                        {post.node.posts?.nodes[0]?.title}
                      </h5>
                    </Link>
                    <p>
                      <span>
                        {post.node.posts?.nodes[0]?.author?.node.name}
                      </span>
                      <span className="related-articles-list-items">
                        {moment(post.node.posts.nodes.date).format(
                          'MMMM D, YYYY'
                        )}
                      </span>
                    </p>
                  </div>
                </div>
              )}
            </>
          );
        })}
      </div>
    </div>
  );
};
